
<script setup>
    import { ref, computed, onMounted, watch } from "vue";
    import L from "leaflet";
    import { GeoLocation } from "o365-utils";
    import { isMobile, resolveMinDelay, hapticsImpact } from "./utils.ts";
    import { flutterHapticFeedback } from "apps.modules.FlutterJavaScriptChannels.ts";

    import MBottomSheet from "./components.BottomSheet.vue";

    const props = defineProps({
        "modelValue": {},
        "latitude": {},
        "longitude": {},
        "save": { type: Function },
        "clear": { type: Function },
    });
    const emit = defineEmits([
        "update:modelValue",
        "save",
        "clear",
    ]);

    const getCurrentPosition = GeoLocation.getCurrentPosition;



    /* constants */

    const MarkerIcon_Blue = new L.Icon({
        iconUrl: "/lib/pwa/icons/marker-icon-2x-blue.png",
        shadowUrl: "/file/db/graphic/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const MarkerIcon_Gold = new L.Icon({
        iconUrl: "/lib/pwa/icons/marker-icon-2x-gold.png",
        shadowUrl: "/file/db/graphic/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });


    const model = computed({
        get: () => props.modelValue,
        set: (val) => emit("update:modelValue", val),
    });

    /*
    const map = ref(null);
    const markerSaved = ref(null);
    const markerUnsaved = ref(null);
    */

    const map = {};
    const markerSaved = {};
    const markerUnsaved = {};

    const isLoadingGeo = ref(false);
    const isSaving = ref(false);
    const isClearing = ref(false);

    const showSpinner = ref(false);

    const locSaved = ref([]);
    const locUnsaved = ref([]);

    const canSave = computed(() => locUnsaved.value);
    const canClear = computed(() => locSaved.value);

    const status = computed(() => {
        if (isLoadingGeo.value) {
            return { icon: "fa-spinner fa-spin", text: $t("Getting location...") };
        } else if (!canSave.value && !canClear.value) {
            return { icon: "fa-times", text: $t("Location is cleared") };
        } else if (canSave.value) {
            return { icon: "fa-exclamation", text: $t("New location is not saved") };
        } else {
            return { icon: "fa-check", text: $t("Location is saved") };
        }
    });

    function setMarkerSaved(latlng) {
        locSaved.value = latlng;
        if (latlng) {
            markerSaved.value.setLatLng(latlng).addTo(map.value);
        } else {
            markerSaved.value.remove();
        }
        setMarkerUnsaved(null);
    }

    function setMarkerUnsaved(latlng) {
        locUnsaved.value = latlng;
        if (latlng) {
            markerUnsaved.value.setLatLng(latlng).addTo(map.value);
        } else {
            markerUnsaved.value.remove();
        }
    }

    function initLeaflet() {
        map.value = L.map("map", {
            center: [0, 0],
            zoom: 1,
            zoomControl: false,
            attributionControl: false,
        });

        markerSaved.value = new L.Marker([0, 0]);
        markerSaved.value.setIcon(MarkerIcon_Blue);

        markerUnsaved.value = new L.Marker([0, 0]);
        markerUnsaved.value.setIcon(MarkerIcon_Gold);

        L.tileLayer().addTo( map.value );

        map.value.on("click", e => {
            setMarkerUnsaved([e.latlng.lat, e.latlng.lng]);
            hapticsImpact("SelectionClick");
        });
    }

    async function getGeolocation(delay) {
        isLoadingGeo.value = true;
        setMarkerUnsaved(null);

        const pos = await resolveMinDelay(getCurrentPosition(), delay || 500);
        const latlng = [pos.coords.latitude, pos.coords.longitude];
        map.value.setView(latlng, 16);
        setMarkerUnsaved(latlng);

        hapticsImpact("MediumImpact");

        isLoadingGeo.value = false;
    }

    function initLogic() {
        if (props.latitude != null && props.longitude != null) {
            const latlng = [props.latitude, props.longitude];
            map.value.setView(latlng, 16);
            setMarkerSaved(latlng);
        } else {
            map.value.setView([0, 0], 1);
            getGeolocation(1000);
        }
    }

    async function save() {
        isSaving.value = true;
        if (props.save) {
            await props.save({ latitude: locUnsaved.value[0], longitude: locUnsaved.value[1] });
            map.value.setView(locUnsaved.value, 16)
            setMarkerSaved(locUnsaved.value);
            hapticsImpact("HeavyImpact");
        }
        isSaving.value = false;
    }

    async function clear() {
        isClearing.value = true;
        if (props.save) {
            await props.save();
            setMarkerSaved(null);
            hapticsImpact("HeavyImpact");
        }
        isClearing.value = false;
    }

    watch(model, async (val) => {
        if (val) {
            initLogic();
        }
    });

    onMounted(() => {
        initLeaflet();
    });

    const mapStyle = computed(() => {
        const style = {
            "position": "relative",
            "height": "300px",
            "background-color": "rgb(90%, 90%, 90%)",
            "border-radius": "1rem",
            "box-shadow": "2px 4px 8px rgb(0%, 0%, 0%, 5%)",
        };
        /*
        if (!isMobile.value) {
            style.height = "400px";
        }
        */
        return style;
    });
</script>

<template>
    <MBottomSheet v-bind="$attrs" v-model="model">
        <template #title>{{ $t("Geolocation") }}</template>
        <template #body>
            <div class="">
                <div class="mt-2 px-3">
                    <div class="position-relative">
                        <div
                            v-once
                            id="map"
                            class="d-flex justify-content-center align-items-center prevent-swipe"
                            :style="mapStyle"
                        />
                        <template v-if="showSpinner || isLoadingGeo">
                            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 500;">
                                <div class="spinner-border" style="">
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="mt-2 px-3">
                    <div class="d-flex align-items-center gap-1 text-muted">
                        <i class="fas fa-fw" :class="status.icon" />
                        <span class="fw-medium">{{ status.text }}</span>
                    </div>
                </div>
                <div class="p-3 d-flex justify-content-end gap-2">
                    <button class="btn btn-outline-primary" @click="getGeolocation()">
                        <template v-if="isLoadingGeo && false">
                            <div class="spinner-border spinner-border-sm" />
                        </template>
                        {{ $t("Get my location") }}
                    </button>
                    <div class="flex-grow-1">
                    </div>
                    <button class="btn btn-outline-primary" @click="clear" :disabled="!canClear">
                        <template v-if="isClearing">
                            <div class="spinner-border spinner-border-sm" />
                        </template>
                        {{ $t("Clear") }}
                    </button>
                    <button class="btn btn-primary" @click="save" :disabled="!canSave">
                        <template v-if="isSaving">
                            <div class="spinner-border spinner-border-sm" />
                        </template>
                        {{ $t("Save") }}
                    </button>
                </div>
            </div>
        </template>
    </MBottomSheet>
</template>
